import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: row;
  padding: 2rem;
  padding-bottom: 0;
`;

export const SideContainer = styled.div`
  width: 100%;
`;

export const UsersFiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  margin: 2rem 0 2.75rem;

  div.filters_align {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    gap: 1.5rem;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: ${(props) => props.theme.fontSize.XXXS};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    font-family: ${(props) => props.theme.fontFamily.Sora};
    color: ${(props) => props.theme.colors.black};

    margin-bottom: 0.5rem;
  }
`;

export const Content = styled.div``;
