import styled from "styled-components";

export const RegisterStoreContainer = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;

  padding: 1.5rem 3rem;
`;

export const RegisterStoreForm = styled.form`
  margin-top: 2.5rem;

  h3 {
    font-size: ${(props) => props.theme.fontSize.XS};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    font-family: ${(props) => props.theme.fontFamily.Roboto};
    color: ${(props) => props.theme.colors["gray-850"]};

    margin-top: 2rem;
  }
`;

export const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-end;
  gap: 1.5rem;

  margin: 1.7rem 0;
`;

export const ButtonAlignContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Separator = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors["gray-100"]};
`;

export const PrinterTitleAlign = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RowValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 1.5rem;
  border: 1px solid ${(props) => props.theme.newColors["gray-500"]};
  border-radius: 0.25rem;
  text-align: center;

  padding: 0.25rem 0.5rem;

  font-size: ${(props) => props.theme.fontSize.XXXS};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelectTitle = styled.span`
  font-size: ${(props) => props.theme.fontSize.XXS};
  font-weight: ${(props) => props.theme.fontWeight.Regular};
  font-family: ${(props) => props.theme.fontFamily.Roboto};
  color: ${(props) => props.theme.colors["black-925"]};
`;

export const InputTitle = styled.span`
  font-size: ${(props) => props.theme.fontSize.XXXS};
  font-weight: ${(props) => props.theme.fontWeight.Regular};
  font-family: ${(props) => props.theme.fontFamily.Roboto};
  color: ${(props) => props.theme.colors.black};

  .required {
    color: ${(props) => props.theme.colors["red-900"]};
    margin-left: 0.375rem;
  }
`;
