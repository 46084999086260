import React, { HTMLAttributes } from "react";
import { useResponsive } from "../../../../hooks/useResponsive";
import * as S from "./styles";

interface TableProps extends HTMLAttributes<HTMLElement> {
  headers: string[];
  children: React.ReactNode;
}

export function RefundTable({ children, headers, ...rest }: TableProps) {
  const { isResponsive } = useResponsive();

  return (
    <S.Container isResponsive={isResponsive} {...rest}>
      <table>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>
                <S.ThStyled>{header}</S.ThStyled>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </S.Container>
  );
}

interface NewTableRow {
  children: React.ReactNode;
}

export function RefundTableRow({ children }: NewTableRow) {
  return <tr>{children}</tr>;
}
