import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useNavigate, useSearchParams } from "react-router-dom";

import { UserListParams, UserTypes } from "../../../../@types/User";
import { Button } from "../../../../components/Button";
import { Pagination } from "../../../../components/Pagination";
import { SearchInput } from "../../../../components/SearchInput";
import { SelectInput } from "../../../../components/SelectInput";
import { WebUserList } from "../../../../components/UserTable/WebUserList";
import { useResponsive } from "../../../../hooks/useResponsive";

import { wrapperRequests } from "../../../../services/api";
import { routesURL } from "../../../../services/routesUrl";
import { ToastPosition, toast } from "react-toastify";
import { UsersPermissionsContext } from "../../../../contexts/UsersPermissions";
import { useContextSelector } from "use-context-selector";
import * as S from "./styles";
import { PageHeader } from "../../../../components/Headers/PageHeader";

interface Toastify {
  success: string;
  error: string;
}

export function Users() {
  const { getAllRoles, allRoles } = useContextSelector(
    UsersPermissionsContext,
    (context) => {
      return context;
    },
  );

  const updatedParams = new URLSearchParams();

  const navigate = useNavigate();

  const [userSearchParams, setUserSearchParams] = useSearchParams();
  const [users, setUsers] = useState<UserTypes[]>([]);
  const [userListParams, setUserListParams] = useState<UserListParams>({
    name: userSearchParams.get("name") ?? "",
    page: userSearchParams.get("page") ?? "1",
    role: userSearchParams.get("role") ?? "",
    rowsPerPage: userSearchParams.get("limit") ?? "5",
    sortBy: userSearchParams.get("sortBy") ?? "",
    totalPages: userSearchParams.get("totalPages") ?? "0",
  });
  const [loading, setLoading] = useState(false);

  const { screenWidth } = useResponsive();

  const getUsers = async () => {
    setLoading(true);
    try {
      const { page, rowsPerPage, role, name, sortBy } = userListParams;

      const { data } = await wrapperRequests(
        routesURL.settings.users.getAllUsers,
        "GET",
        {
          params: {
            page,
            limit: rowsPerPage,
            role,
            name,
            sortBy,
          },
        },
      );

      setUsers(data.content);
      setUserListParams((state) => ({
        ...state,
        totalPages: data.totalPages || "0",
      }));
    } catch (error) {
      throw new Error(String(error));
    } finally {
      setLoading(false);
    }
  };

  const resetParams = () => {
    setUserSearchParams({
      limit: "5",
      page: "1",
    });
  };

  const toastify = (
    type: keyof Toastify,
    message: string,
    position: ToastPosition,
  ) => {
    toast[type](message, {
      position,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  async function handleDeleteUser(id: string) {
    try {
      await wrapperRequests(`/users/${id}`, "DELETE");

      setUsers((state) => state.filter((user) => user.id !== id));

      toastify("success", "🚴‍♂️ User deleted successfully!", "top-center");
    } catch (error) {
      toastify("error", "User delete error.", "top-center");
    }
  }

  const updateSearchParams = () => {
    userListParams?.name && updatedParams.set("name", userListParams.name);
    userListParams?.role && updatedParams.set("role", userListParams.role);

    updatedParams.set("limit", String(userListParams.rowsPerPage));
    updatedParams.set("page", "1");

    setUserSearchParams(updatedParams);
  };

  const updateUserParams = (key: keyof UserListParams, value: string) => {
    setUserListParams((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const updateRows = (value: number) => {
    setUserSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("limit", String(value));
      updatedParams.set("page", "1");
      return updatedParams;
    });
  };

  const updatePage = (value: number) => {
    setUserSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("page", String(value));
      return updatedParams;
    });
  };

  const updateSortBy = (value: string) => {
    setUserSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("sortBy", String(value));
      return updatedParams;
    });
  };

  useEffect(() => {
    getUsers();
    getAllRoles();
  }, []);

  return (
    <S.Container>
      <S.SideContainer>
        {/* <PageHeader
          title="Users"
          description="See all users registered in the system"
        /> */}
        <PageHeader
          title="Users"
          description="See all users registered in the system"
          hasButtons
          refreshFunction={() => resetParams()}
        />

        <S.UsersFiltersContainer>
          <div className="filters_align">
            <S.FilterContainer>
              <SearchInput
                title="Search by name"
                value={userListParams.name ?? ""}
                searchIcon
                disabled={loading}
                placeholder={"Employee"}
                onPressEnter={() => updateSearchParams()}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  updateUserParams("name", event.target.value);
                }}
              />
            </S.FilterContainer>

            <S.FilterContainer>
              <SelectInput
                title="Filter by roles"
                disabled={loading}
                value={userListParams.role}
                placeholder="Select an option"
                options={allRoles.map((role) => ({
                  id: role.id,
                  value: role.name,
                  label: role.name,
                }))}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  updateUserParams("role", event.target.value); // Correção
                }}
              />
            </S.FilterContainer>

            <Button disabled={loading} onClick={() => updateSearchParams()}>
              {loading ? (
                <ReactLoading
                  height={24}
                  width={24}
                  type={"spin"}
                  color="#fff"
                />
              ) : (
                "Search"
              )}
            </Button>
          </div>

          <Button onClick={() => navigate("/settings/users/create")}>
            New User
          </Button>
        </S.UsersFiltersContainer>
        <S.Content>
          <WebUserList
            onHandleDeleteUser={handleDeleteUser}
            data={users}
            loading={loading}
            selectSortBy={updateSortBy}
          />

          <Pagination
            selectPage={updatePage}
            page={Number(userListParams.page)}
            placeholder={String(userListParams.rowsPerPage)}
            selectRowsPerPage={updateRows}
            totalPages={Number(userListParams.totalPages)}
          />
        </S.Content>
      </S.SideContainer>
    </S.Container>
  );
}
