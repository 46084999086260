import { useCallback, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { Button } from "../../../../../components/New/Button";
import { PageHeader } from "../../../../../components/Headers/PageHeader";
import { AccordionWithSwitch } from "../components/AccordionWithSwitch";
import * as S from "./styles";

import { useContextSelector } from "use-context-selector";
import { RolesContext } from "../../../../../contexts/RolesContext";
import { Input } from "../../../../../components/New/Input";

export function RegisterRole() {
  const { roleId } = useParams();

  const navigate = useNavigate()

  const {
    fetchRole,
    fetchAllPermissions,
    loadings,
    permissions,
    roleData,
    handleExpandPermissions,
    handleCheckPermissions,
    handleCheckOrRemoveAllPermissions,
    handleUpdateRoleName,
    handleEditRole,
    handleCreateRole,
  } = useContextSelector(RolesContext, (context) => {
    return context;
  });

  const { webPermissions, appPermissions } = {
    webPermissions: permissions.WEB ? Object.values(permissions.WEB) : null,
    appPermissions: permissions.APP ? Object.values(permissions.APP) : null,
  };
 
  const isEditRole = roleId;

  const [accordionIsOpen, setAccordionIsOpen] = useState("");
  const [notHaveAnyPermission, setNotHaveAnyPermission] = useState(false);

  const handleAccordion = useCallback(
    (value: string) => setAccordionIsOpen(value),
    [],
  );

  const handlePermissions = () => {
    const hasAnyPermissionWeb =
      webPermissions &&
      webPermissions.some((permission) => {
        return (
          permission?.hasPermission &&
          permission?.data?.some((child) => {
            return child?.hasPermission;
          })
        );
      });

    const hasAnyPermissionApp =
      appPermissions &&
      appPermissions.some((permission) => {
        return (
          permission && permission.data?.some((child) => child.hasPermission)
        );
      });

    if (!hasAnyPermissionWeb && !hasAnyPermissionApp) {
      setNotHaveAnyPermission(true);
    } else {
      setNotHaveAnyPermission(false);
    }
  };

  useEffect(() => {
    if (isEditRole) {
      fetchRole(isEditRole);
    } else {
      fetchAllPermissions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handlePermissions();
  }, [permissions]);

  return (
    <S.Container>
      <PageHeader
        title={isEditRole ? "Roles" : "Roles"}
        description="Create stores or manage active stores in the system"
        customBreadcrumbString={isEditRole ? "Edit Role" : "Create Role"}
        hasBorder={false}
      />

      <S.Divider />

      {loadings.permissions ? (
        <S.LoadingContainer>
          <ReactLoading height={48} width={48} type={"spin"} color="#000" />
        </S.LoadingContainer>
      ) : (
        <S.Form autoComplete="off">
          <S.InputRowContainer>
            <Input
              title="Role Name"
              type="text"
              placeholder="Role name"
              value={roleData.name}
              onChange={(event) => handleUpdateRoleName(event.target.value)}
            />
          </S.InputRowContainer>

          <S.Title>Web</S.Title>
          <S.MenuAccessRowContainer>
            {webPermissions?.map((permission) => (
              <AccordionWithSwitch
                key={permission?.name}
                handleExpandPermissions={handleExpandPermissions}
                handleCheckPermissions={handleCheckPermissions}
                handleCheckOrRemoveAllPermissions={
                  handleCheckOrRemoveAllPermissions 
                }
                permission={permission}
                onValueChange={handleAccordion}
                value={accordionIsOpen}
              />
            ))}
          </S.MenuAccessRowContainer>

          <S.Title>App</S.Title>
          <S.MenuAccessRowContainer>
            {appPermissions?.map((permission) => (
              <AccordionWithSwitch
                key={permission?.name}
                handleExpandPermissions={handleExpandPermissions}
                handleCheckPermissions={handleCheckPermissions}
                handleCheckOrRemoveAllPermissions={
                  handleCheckOrRemoveAllPermissions
                }
                permission={permission}
                onValueChange={handleAccordion}
                value={accordionIsOpen}
              />
            ))}
          </S.MenuAccessRowContainer>

          <S.ButtonContainer>
            <Button 
                varitant="ghost" 
                title="Cancel" 
                onClick={(event) => {
                  event.preventDefault();
                  navigate(`/settings/roles`);
              }}
            />
            <Button
                disabled={loadings.editOrCreateRole || notHaveAnyPermission}
                onClick={(event) => {
                    event.preventDefault();
                    isEditRole ? handleEditRole(isEditRole) : handleCreateRole();
                }}
                title="Save"
            >
                {loadings.editOrCreateRole || loadings.permissions ? (
                    <ReactLoading height={20} width={20} type="spin" color="#fff" />
                ) : (
                    "Save"
                )}
            </Button>
        </S.ButtonContainer>

        </S.Form>
      )}
    </S.Container>
  );
}
