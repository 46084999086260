export const RefundReason = [
  {
    id: "1",
    label: "Customer",
    value: "customer",
  },
  {
    id: "2",
    label: "Damage",
    value: "damage",
  },
  {
    id: "3",
    label: "Restock",
    value: "restock",
  },
  {
    id: "4",
    label: "Other",
    value: "other",
  },
];

export const RefundPercentage = [
  {
    id: "1",
    label: "100%",
    value: "100%",
  },
  {
    id: "2",
    label: "90%",
    value: "90%",
  },
  {
    id: "3",
    label: "80%",
    value: "80%",
  },
  {
    id: "4",
    label: "70%",
    value: "70%",
  },
  {
    id: "5",
    label: "60%",
    value: "60%",
  },
  {
    id: "6",
    label: "50%",
    value: "50%",
  },
];

export const StockLocationInitialValue = [
  {
    id: "63647232c09b1fa35103bec6",
    tenantName: "Incycle",
    name: "Pomona Fulfillment Center",
    code: "9",
  },
];

export const RefundCondition = [
  {
    id: "1",
    label: "New",
    value: "New",
  },
  {
    id: "2",
    label: "Open Box",
    value: "Open Box",
  },
  {
    id: "3",
    label: "Blem",
    value: "Blem",
  },
  {
    id: "4",
    label: "Missing Parts",
    value: "Missing Parts",
  },
  {
    id: "5",
    label: "No Box",
    value: "No Box",
  },
  {
    id: "6",
    label: "Pre-Owned",
    value: "Pre-Owned",
  },
  {
    id: "7",
    label: "Take-Off",
    value: "Take-Off",
  },
];

export const EbayRefundReason = [
  {
    id: "1",
    label: "Buyer cancel",
    value: "BUYER_CANCEL",
  },
  {
    id: "2",
    label: "Seller cancel",
    value: "SELLER_CANCEL",
  },
  {
    id: "3",
    label: "Item not received",
    value: "ITEM_NOT_RECEIVED",
  },
];
