import { format, parseISO } from "date-fns";
import { useState } from "react";
import { FaClock } from "react-icons/fa";
import { MdContentCopy, MdDoDisturb, MdDone } from "react-icons/md";
import { RiTruckFill } from "react-icons/ri";
import {
  OrderDetailSale,
  OrderDetailSaleItem,
  ProductList,
  ShipmentsPromotion,
} from "../../../../../../@types/Orders";
import {
  PickingItemStatus,
  PickingItemStatusConverter,
} from "../../../../../../enums/Orders";
import { colors } from "../../../../../../styles/themes/default/foundations/colors";
import { OrdersAccordion } from "../OrdersAccordion";
import * as S from "./styles";
import { useContextSelector } from "use-context-selector";
import { OrdersContext } from "../../../../../../contexts/OrdersContext";
import { Input } from "../../../../../../components/New/Input";
import { Button } from "../../../../../../components/New/Button";
import { Table } from "../../../../../../components/New/Table";
import { ColumnDef } from "@tanstack/react-table";
import MoneyBack from "../../../../../../assets/icons/money-back.svg";
import { ConfirmationModal } from "../../../../../../components/ConfirmationModal";

const promotionsTotalAmount = (promotion: ShipmentsPromotion[]) => {
  return promotion?.reduce((accumulator, currentPromotion) => {
    return accumulator + currentPromotion.amount;
  }, 0);
};

const priceColumns: ColumnDef<ProductList>[] = [
  {
    accessorFn: (row) => row.product?.sku || "",
    header: "SKU",
    cell: (props) => <S.BillingText>{props.getValue()}</S.BillingText>,
  },
  {
    accessorKey: "",
    header: "Checkout Status",
    size: 200,
    cell: (props) => (
      <S.StatusContainer variant={"Completed"}>Completed</S.StatusContainer>
    ),
  },
  {
    accessorFn: (row) => row.productSummary.unitPrice || "",
    header: "Unit Price",
    cell: (props) => (
      <S.BillingText>
        $ {(props?.row.original.productSummary?.unitPrice / 100).toFixed(2)}
      </S.BillingText>
    ),
  },
  {
    accessorFn: (row) => row?.productSummary?.taxPrice || "",
    header: "Tax",
    size: 150,
    cell: (props) => (
      <S.BillingText>
        ${" "}
        {props?.row.original?.productSummary?.taxPrice
          ? (props?.row.original?.productSummary?.taxPrice / 100).toFixed(2)
          : "0.00"}
        <span
          style={{
            color: colors["gray-700"],
            marginLeft: "0.3rem",
          }}
        >
          (
          {props?.row.original?.productSummary?.taxPrice
            ? (
                (props?.row.original?.productSummary?.taxPrice /
                  props?.row.original?.quantity /
                  props?.row.original?.productSummary?.unitPrice) *
                100
              )?.toFixed(2)
            : 0}
          %)
        </span>
      </S.BillingText>
    ),
  },
  {
    accessorKey: "quantity",
    header: "Quantity",
    cell: (props) => <S.BillingText>{props.getValue()}</S.BillingText>,
  },
  {
    accessorFn: (row) => row.promotions || "",
    header: "Discount",
    cell: (props) => (
      <S.BillingText style={{ color: colors["red-450"] }}>
        (${" "}
        {promotionsTotalAmount(props.row.original.promotions)?.toFixed(2) ||
          "0.00"}
        )
      </S.BillingText>
    ),
  },
  {
    accessorKey: "promotions",
    header: "Item Total",
    cell: (props) => (
      <S.BillingText>
        ${" "}
        {Number(
          (props.row.original?.productSummary?.unitPrice *
            props.row.original?.quantity +
            props.row.original?.productSummary?.taxPrice +
            promotionsTotalAmount(props.row.original?.promotions)) /
            100 || 0,
        )?.toFixed(2)}
      </S.BillingText>
    ),
  },
];

export function OrderDetailsComplete() {
  const [copyToClipboard, setCopyToClipboard] = useState({
    generalShippingData: false,
    shippingEmail: false,
  });

  const { loading, orderDetails, cancelShipmentsItem } = useContextSelector(
    OrdersContext,
    (context) => {
      return context;
    },
  );

  const {
    createdAt,
    requestedShippingCarrier,
    requestedShippingClass,
    mustDeliverBy,
    privateNotes,
    publicNotes,
    additionalCostOrDiscount,
  } = orderDetails;

  const shipmentsColumns: ColumnDef<OrderDetailSaleItem>[] = [
    {
      accessorFn: (row) =>
        row.product?.lightspeedProduct?.manufacturerSku ||
        row.product?.sku ||
        "",
      header: "SKU",
      size: 130,
      cell: (props) => <S.BillingText>{props.getValue()}</S.BillingText>,
    },
    {
      accessorFn: (row) =>
        row?.product?.lightspeedProduct?.description || row?.product?.name,
      header: "Title",
      size: 332,
      cell: (props) => <S.BillingText>{props.getValue()}</S.BillingText>,
    },
    {
      accessorKey: "quantity",
      header: "Quantity",
      size: 100,
      cell: (props) => <S.BillingText>{props.getValue()}</S.BillingText>,
    },
    {
      accessorFn: (row) =>
        row?.product?.lightspeedProduct?.description || row?.product?.name,
      header: "Status",
      size: 150,
      cell: (props) => (
        <S.StatusContainer variant={props.row.original.status}>
          {PickingItemStatusConverter[props.row.original.status]}
        </S.StatusContainer>
      ),
    },
    {
      accessorKey: "",
      header: "Actions",
      size: 70,
      cell: (props) => (
        <S.ActionsContainer>
          <ConfirmationModal
            title={"Are you sure about canceling this order line?"}
            description={
              "This action is irreversible. It will only cancel the Shipstation Line Item and will not affect anything in Lightspeed."
            }
            variant="delete"
            confirmFunction={() =>
              cancelShipmentsItem(
                props.row.original.itemID,
                props.row.original.saleLineID,
              )
            }
          >
            <S.ActionButton
              title="Cancel item"
              disabled={
                props.row.original.status !== PickingItemStatus.PENDING ||
                loading.cancelItem ||
                orderDetails.sourceMarketplace !== "SBC"
              }
            >
              <MdDoDisturb size={20} />
            </S.ActionButton>
          </ConfirmationModal>
          <S.ActionButton title="Refund item" disabled>
            <img src={MoneyBack} alt="" />
          </S.ActionButton>
        </S.ActionsContainer>
      ),
    },
  ];

  const handleCopyInfo = (
    copyType: keyof typeof copyToClipboard,
    info?: string,
  ) => {
    if (info) {
      navigator.clipboard.writeText(info);

      setCopyToClipboard((state) => ({
        ...state,
        [copyType]: true,
      }));

      setTimeout(() => {
        setCopyToClipboard((state) => ({
          ...state,
          [copyType]: false,
        }));
      }, 2000);
    }
  };

  const subTotal = (
    totalValue: number,
    shippingValue: number,
    taxValue: number,
  ) => {
    const total = totalValue ?? 0;
    const shipping = shippingValue ?? 0;
    const tax = taxValue ?? 0;

    return (total - shipping - tax) / 100;
  };

  return (
    <>
      <OrdersAccordion title="Summary">
        <S.GridContainer>
          <S.SumaryContentColumn>
            <div>
              <S.SumaryDataTitle>Line items:</S.SumaryDataTitle>
              <S.SumaryData>{orderDetails?.sales?.length}</S.SumaryData>
            </div>

            <div>
              <S.SumaryDataTitle>Buyer:</S.SumaryDataTitle>
              <S.SumaryData>
                {orderDetails?.billing?.lastName ??
                  orderDetails?.shipping?.lastName}
                ,{" "}
                {orderDetails?.billing?.firstName ??
                  orderDetails?.shipping?.firstName}
              </S.SumaryData>
            </div>

            <div>
              <S.SumaryDataTitle>Created:</S.SumaryDataTitle>
              <S.SumaryData>
                {createdAt && format(parseISO(createdAt), "MM/dd/yyyy hh:mm a")}
              </S.SumaryData>
            </div>

            <div>
              <S.SumaryDataTitle>Imported:</S.SumaryDataTitle>
              <S.SumaryData>
                {orderDetails?.importDateUTC &&
                  format(
                    parseISO(orderDetails?.importDateUTC),
                    "MM/dd/yyyy hh:mm a",
                  )}
              </S.SumaryData>
            </div>
          </S.SumaryContentColumn>
          <S.SumaryContentColumn>
            <div>
              <S.SumaryDataTitle>ChannelAdvisor Order ID:</S.SumaryDataTitle>
              <S.SumaryData>{orderDetails?.storeOrderID}</S.SumaryData>
            </div>

            <div>
              <S.SumaryDataTitle>Seller Order ID:</S.SumaryDataTitle>
              <S.SumaryData>{orderDetails?.secondaryStoreOrderID}</S.SumaryData>
            </div>

            <div>
              <S.SumaryDataTitle>Site Order ID:</S.SumaryDataTitle>
              <S.SumaryData>{orderDetails?.storeOrderID}</S.SumaryData>
            </div>
          </S.SumaryContentColumn>
          <S.SumaryContentColumn>
            <div>
              <S.SumaryDataTitle>Req. Shipping Shopify:</S.SumaryDataTitle>
              <S.SumaryData>{requestedShippingCarrier}</S.SumaryData>
            </div>

            <div>
              <S.SumaryDataTitle>Carrier/Class:</S.SumaryDataTitle>
              <S.SumaryData>{requestedShippingClass}</S.SumaryData>
            </div>

            <div>
              <S.SumaryDataTitle>Estimated Ship</S.SumaryDataTitle>
              <S.SumaryData>
                <Input
                  defaultValue={
                    (orderDetails?.estimateShip &&
                      format(
                        parseISO(orderDetails?.estimateShip),
                        "MM/dd/yyyy ",
                      )) ||
                    ""
                  }
                  disabled
                />
              </S.SumaryData>
            </div>

            <div>
              <S.SumaryDataTitle>Must Deliver By</S.SumaryDataTitle>
              <S.SumaryData>
                <Input
                  defaultValue={
                    (mustDeliverBy &&
                      format(parseISO(mustDeliverBy), "MM/dd/yyyy ")) ||
                    ""
                  }
                  disabled
                />
              </S.SumaryData>
            </div>
          </S.SumaryContentColumn>
        </S.GridContainer>
      </OrdersAccordion>

      <S.Separator />

      <OrdersAccordion
        title={`Shipments Id #${orderDetails?.orderReferenceNumber}`}
      >
        {orderDetails?.sales &&
          orderDetails?.sales?.map((sale: OrderDetailSale, saleIndex) => {
            return (
              <S.ShipmentsContainer key={sale.id}>
                <S.HorizontalContainer>
                  <S.ShipContainer
                    variant={
                      sale?.shipmentData?.trackingNumber ? "SHIPPED" : "PENDING"
                    }
                  >
                    <RiTruckFill
                      size={24}
                      color={
                        !sale?.shipmentData?.trackingNumber
                          ? colors["red-500"]
                          : colors["green-650"]
                      }
                    />
                    {sale?.shipmentData?.trackingNumber ?? "Unshipped"}
                    <span style={{ marginLeft: "0.1rem" }}>
                      {sale?.shipmentData?.dispatchDate &&
                        format(
                          parseISO(sale?.shipmentData?.dispatchDate),
                          "(MM/dd/yyyy hh:mm a)",
                        )}
                    </span>
                  </S.ShipContainer>
                  {/* <Button disabled title="Save Changes" width="8rem" /> */}
                </S.HorizontalContainer>

                <S.ShipmentsGrid>
                  <S.InputContainer>
                    <Input
                      title="Shipping Carrier/Class"
                      defaultValue={requestedShippingClass || ""}
                      disabled
                    />
                    <Input title="Return Tracking Number" disabled />
                  </S.InputContainer>
                  <S.InputContainer>
                    <Input
                      title="Tracking Number"
                      defaultValue={sale?.shipmentData?.trackingNumber || ""}
                      disabled
                    />
                    <Input
                      title="Distribution Center"
                      defaultValue={sale?.storeName || ""}
                      disabled
                    />
                  </S.InputContainer>
                  <S.InputContainer>
                    <Input
                      title="Shipment Date"
                      defaultValue={
                        sale?.shipmentData?.dispatchDate
                          ? format(
                              parseISO(sale?.shipmentData?.dispatchDate),
                              "MM/dd/yyyy",
                            )
                          : ""
                      }
                      disabled
                    />
                  </S.InputContainer>
                </S.ShipmentsGrid>

                <S.Separator
                  style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
                />

                <S.ProductListsTitle>Product list</S.ProductListsTitle>
                <S.NumberOfProducts>
                  {sale?.items?.length} products listed
                </S.NumberOfProducts>

                {/* <S.TableContainer>
                  <TableComponent>
                    <thead>
                      <tr>
                        <ThComponent withArrows={false}>SKU</ThComponent>
                        <ThComponent withArrows={false}>Title</ThComponent>
                        <ThComponent withArrows={false}>Quantity</ThComponent>
                        <ThComponent withArrows={false}>Status</ThComponent>
                        <ThComponent withArrows={false}>Cancel</ThComponent>
                      </tr>
                    </thead>

                    <tbody>
                      {sale?.items?.length ? (
                        sale?.items?.map((item: OrderDetailSaleItem) => (
                          <TrComponent key={item.itemID}>
                            <TdComponent tdWidth={"15%"}>
                              {item?.product?.lightspeedProduct
                                ?.manufacturerSku ||
                                item?.product?.sku ||
                                ""}
                            </TdComponent>
                            <TdComponent tdWidth={"auto"}>
                              {item?.product?.lightspeedProduct?.description ||
                                item?.product?.name}
                            </TdComponent>
                            <TdComponent tdWidth={"10%"}>
                              {item?.quantity}
                            </TdComponent>
                            <TdComponent tdWidth={"11rem"}>
                              <S.StatusContainer variant={item?.status}>
                                {PickingItemStatusConverter[item?.status]}
                              </S.StatusContainer>
                            </TdComponent>
                            <TdComponent tdWidth={"10%"}>
                              <ConfirmationModal
                                title={
                                  "Are you sure about canceling this order line?"
                                }
                                description={
                                  "This action is irreversible. It will only cancel the Shipstation Line Item and will not affect anything in Lightspeed."
                                }
                                variant="delete"
                                confirmFunction={() =>
                                  cancelShipmentsItem(item.id, sale.id)
                                }
                              >
                                <ActionsButton
                                  title="Cancel item"
                                  disabled={
                                    item.status !== PickingItemStatus.PENDING ||
                                    loading.cancelItem
                                  }
                                >
                                  {loading.cancelItem &&
                                  loading.id === item.id ? (
                                    <ReactLoading
                                      height={24}
                                      width={24}
                                      type={"spin"}
                                      color={colors["gray-800"]}
                                    />
                                  ) : (
                                    <MdDoDisturb
                                      color={colors["gray-800"]}
                                      size={32}
                                    />
                                  )}
                                </ActionsButton>
                              </ConfirmationModal>
                            </TdComponent>
                          </TrComponent>
                        ))
                      ) : (
                        <TrComponent>
                          <TdComponent tdWidth={"10%"}>-</TdComponent>
                          <TdComponent tdWidth={"auto"}>-</TdComponent>
                          <TdComponent tdWidth={"10%"}>-</TdComponent>
                          <TdComponent tdWidth={"11rem"}>
                            <S.StatusContainer variant="otherwise">
                              -
                            </S.StatusContainer>
                          </TdComponent>
                        </TrComponent>
                      )}
                    </tbody>
                  </TableComponent>
                </S.TableContainer> */}

                {sale?.items?.length && (
                  <Table
                    columns={shipmentsColumns}
                    columnToFilter="task"
                    data={sale.items}
                    key={orderDetails.processStatus}
                    style={{ marginTop: "1.5rem" }}
                  />
                )}

                <S.Separator
                  style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
                />

                <S.ActivityContainer>
                  <S.NotesContainer>
                    <S.SumaryData>Internal Notes</S.SumaryData>
                    <S.ActivityTextArea cols={10} rows={3} disabled />
                    {/* <Button
                      disabled
                      title="Save Notes"
                      style={{ alignSelf: "flex-end" }}
                    /> */}
                  </S.NotesContainer>
                  <S.ActivityAlign>
                    <S.SumaryData>Activity Log</S.SumaryData>

                    <S.ActivityContent>
                      {sale?.actionHistory?.map(
                        ({ createdAt, message }, index) => (
                          <S.ActivityReport key={index}>
                            <S.ActivityDate>
                              <FaClock size={20} color={colors["gray-850"]} />
                              {createdAt &&
                                format(
                                  parseISO(createdAt),
                                  "MM/dd/yyyy hh:mm a",
                                )}
                            </S.ActivityDate>

                            <span>{message}</span>
                          </S.ActivityReport>
                        ),
                      )}
                    </S.ActivityContent>
                  </S.ActivityAlign>
                </S.ActivityContainer>

                {saleIndex < orderDetails?.sales?.length - 1 && (
                  <S.Separator
                    style={{
                      marginTop: "2.5rem",
                      marginBottom: "2.5rem",
                    }}
                  />
                )}
              </S.ShipmentsContainer>
            );
          })}
      </OrdersAccordion>

      <S.Separator />

      <OrdersAccordion title="History & Activity">
        {/* <S.ProductListsTitle style={{ marginTop: "2rem" }}>
          Transactions
        </S.ProductListsTitle>

        <TableComponent>
          <thead>
            <tr>
              <ThComponent withArrows={false}>Date</ThComponent>
              <ThComponent withArrows={false}>Type</ThComponent>
              <ThComponent withArrows={false}>Checkout Status</ThComponent>
              <ThComponent withArrows={false}>Payment Status</ThComponent>
              <ThComponent withArrows={false}>Amout</ThComponent>
              <ThComponent withArrows={false}>
                Merchant Reference number
              </ThComponent>
              <ThComponent withArrows={false}>Details</ThComponent>
            </tr>
          </thead>

          <tbody>
            <TrComponent>
              <TdComponent tdWidth={"10%"}>-</TdComponent>
              <TdComponent tdWidth={"auto"}>-</TdComponent>
              <TdComponent tdWidth={"auto"}>-</TdComponent>
              <TdComponent tdWidth={"auto"}>
                <S.StatusContainer variant={"pending"}>{"-"}</S.StatusContainer>
              </TdComponent>

              <TdComponent tdWidth={"auto"}>-</TdComponent>
              <TdComponent tdWidth={"auto"}>-</TdComponent>
              <TdComponent tdWidth={"auto"}>-</TdComponent>
            </TrComponent>
          </tbody>
        </TableComponent> */}

        <S.ProductListsTitle
          style={{ marginTop: "2rem", marginBottom: "1.5rem" }}
        >
          Notes
        </S.ProductListsTitle>

        <S.NotesContainerAlign>
          <S.NotesContainer>
            <S.SumaryData>Public Notes</S.SumaryData>
            <S.ActivityTextArea
              style={{ maxWidth: "35rem", maxHeight: "6rem" }}
              defaultValue={publicNotes}
              disabled
            />
          </S.NotesContainer>

          <S.NotesContainer>
            <S.SumaryData>Private Notes</S.SumaryData>
            <S.ActivityTextArea
              style={{ maxWidth: "31rem", maxHeight: "6rem" }}
              defaultValue={privateNotes}
              disabled
            />
          </S.NotesContainer>
        </S.NotesContainerAlign>
      </OrdersAccordion>

      <S.Separator />

      <OrdersAccordion title="Shipping & Billing">
        <S.BillingContainer>
          <S.BillingTextContainer>
            <S.BillingAlignContainer>
              <div>
                <S.BillingTitleContainer>
                  <S.BillingTitle>Shipping</S.BillingTitle>
                  {/* {copyToClipboard.generalShippingData ? (
                        <MdDone title="Copied" size={16} />
                      ) : (
                        <MdContentCopy
                          title="Copy"
                          size={16}
                          onClick={() =>
                            handleCopyInfo("generalShippingData", shippingData)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      )} */}
                </S.BillingTitleContainer>
                <S.BillingText>
                  {orderDetails?.shipping?.lastName ?? ""},{" "}
                  {orderDetails?.shipping?.firstName ?? ""}
                </S.BillingText>
                <S.BillingText>
                  {orderDetails?.shipping?.companyName}
                </S.BillingText>
                <S.BillingText>
                  {orderDetails?.shipping?.addressLine1}{" "}
                  {orderDetails?.shipping?.addressLine2}
                </S.BillingText>
                <S.BillingText>
                  {orderDetails?.shipping?.city},{" "}
                  {orderDetails?.shipping?.stateOrProvinceName} -
                  {orderDetails?.shipping?.postalCode}
                </S.BillingText>
                <S.BillingText>{orderDetails?.shipping?.country}</S.BillingText>
              </div>
              <div>
                <S.BillingTitleContainer>
                  <S.BillingTitle>Email</S.BillingTitle>
                  {copyToClipboard.shippingEmail ? (
                    <MdDone title="Copied" size={16} />
                  ) : (
                    <MdContentCopy
                      title="Copy"
                      size={16}
                      onClick={() =>
                        handleCopyInfo(
                          "shippingEmail",
                          orderDetails?.buyerEmailAddress
                            ? orderDetails?.buyerEmailAddress
                            : "",
                        )
                      }
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </S.BillingTitleContainer>
                <S.BillingText>{orderDetails?.buyerEmailAddress}</S.BillingText>

                <S.BillingTitle
                  style={{ marginTop: "1.5rem", marginBottom: "0.5rem" }}
                >
                  Phone
                </S.BillingTitle>
                <S.BillingText>
                  {orderDetails?.shipping?.daytimePhone}
                </S.BillingText>
              </div>
            </S.BillingAlignContainer>

            <S.BillingTitle
              style={{ marginTop: "2rem", marginBottom: "0.5rem" }}
            >
              Buyer Instructions
            </S.BillingTitle>
            <S.BillingText>{orderDetails?.specialInstructions}</S.BillingText>
          </S.BillingTextContainer>

          <div style={{ flex: 1, marginLeft: "3.5rem" }}>
            <S.BillingAlignContainer>
              <div>
                <S.BillingTitle style={{ marginBottom: "0.5rem" }}>
                  Billing
                </S.BillingTitle>
                <S.BillingText>
                  {orderDetails?.billing?.lastName ??
                    orderDetails?.shipping?.lastName}
                  ,{" "}
                  {orderDetails?.billing?.firstName ??
                    orderDetails?.shipping?.firstName}
                </S.BillingText>
                <S.BillingText>
                  {orderDetails?.billing?.companyName}
                </S.BillingText>
                <S.BillingText>
                  {orderDetails?.billing?.addressLine1}{" "}
                  {orderDetails?.billing?.addressLine2}
                </S.BillingText>
                <S.BillingText>
                  {orderDetails?.billing?.city},{" "}
                  {orderDetails?.billing?.country} -{" "}
                  {orderDetails?.billing?.postalCode}
                </S.BillingText>
                <S.BillingText>{orderDetails?.billing?.country}</S.BillingText>
              </div>
              <div>
                <S.BillingTitle style={{ marginBottom: "0.5rem" }}>
                  Buyer ID
                </S.BillingTitle>
                <S.BillingText>{orderDetails?.buyerEmailAddress}</S.BillingText>

                <S.BillingTitle
                  style={{ marginTop: "1.5rem", marginBottom: "0.5rem" }}
                >
                  Phone
                </S.BillingTitle>
                <S.BillingText>
                  {orderDetails?.billing?.daytimePhone}
                </S.BillingText>
              </div>
            </S.BillingAlignContainer>
          </div>
        </S.BillingContainer>
      </OrdersAccordion>

      <S.Separator />

      <OrdersAccordion title="Price & Promotions">
        <S.PriceContainer>
          {/* <S.TableContainer>
            <TableComponent>
              <thead>
                <tr>
                  <ThComponent withArrows={false}>SKU</ThComponent>
                  <ThComponent withArrows={false}>Checkout Status</ThComponent>
                  <ThComponent withArrows={false}>Unit Price</ThComponent>
                  <ThComponent withArrows={false}>Tax</ThComponent>
                  <ThComponent withArrows={false}>Quantity</ThComponent>
                  <ThComponent withArrows={false}>Discount</ThComponent>
                  <ThComponent withArrows={false}>Item Total</ThComponent>
                </tr>
              </thead>

              <tbody>
                {orderDetails?.items &&
                  orderDetails?.items?.map((item: ProductList) => (
                    <TrComponent key={item?.id}>
                      <TdComponent tdWidth={"10%"}>
                        {item?.product?.sku}
                      </TdComponent>
                      <TdComponent tdWidth={"14rem"}>
                        <S.StatusContainer variant={"Completed"}>
                          Completed
                        </S.StatusContainer>
                      </TdComponent>
                      <TdComponent tdWidth={"auto"}>
                        ${(item?.productSummary?.unitPrice / 100).toFixed(2)}
                      </TdComponent>
                      <TdComponent tdWidth={"auto"}>
                        $
                        {item?.productSummary?.taxPrice
                          ? (item?.productSummary?.taxPrice / 100).toFixed(2)
                          : "0.00"}
                        <span
                          style={{
                            color: colors["gray-700"],
                            marginLeft: "0.3rem",
                          }}
                        >
                          (
                          {item?.productSummary?.taxPrice
                            ? (
                                (item?.productSummary?.taxPrice /
                                  item?.quantity /
                                  item?.productSummary?.unitPrice) *
                                100
                              )?.toFixed(2)
                            : 0}
                          %)
                        </span>
                      </TdComponent>

                      <TdComponent tdWidth={"auto"}>
                        {item.quantity}
                      </TdComponent>
                      <TdComponent tdWidth={"auto"}>
                        <p style={{ color: colors["red-450"] }}>
                          ($
                          {promotionsTotalAmount(item?.promotions)?.toFixed(
                            2,
                          ) || "0.00"}
                          )
                        </p>
                      </TdComponent>
                      <TdComponent tdWidth={"auto"}>
                        $
                        {Number(
                          (item?.productSummary?.unitPrice * item?.quantity +
                            item?.productSummary?.taxPrice +
                            promotionsTotalAmount(item?.promotions)) /
                            100 || 0,
                        )?.toFixed(2)}
                      </TdComponent>
                    </TrComponent>
                  ))}
              </tbody>
            </TableComponent>
          </S.TableContainer> */}

          {orderDetails?.items && (
            <Table
              columns={priceColumns}
              columnToFilter="task"
              data={orderDetails?.items}
              modalOnClick
            />
          )}

          <S.PriceDetails>
            <S.PriceWrapper>
              <p>Subtotal:</p>
              <p>
                ${" "}
                {subTotal(
                  orderDetails?.orderSummary?.totalPrice,
                  orderDetails?.orderSummary?.totalShippingPrice,
                  orderDetails?.orderSummary?.totalTaxPrice,
                ).toFixed(2)}
              </p>
            </S.PriceWrapper>
            <S.PriceWrapper>
              <p>Shipping:</p>
              <p>
                ${" "}
                {orderDetails?.orderSummary?.totalShippingPrice
                  ? (
                      orderDetails?.orderSummary?.totalShippingPrice / 100
                    ).toFixed(2)
                  : "0.00"}
              </p>
            </S.PriceWrapper>

            <S.PriceWrapper>
              <p>Tax:</p>
              <p>
                ${" "}
                {orderDetails?.orderSummary?.totalTaxPrice
                  ? (orderDetails?.orderSummary?.totalTaxPrice / 100).toFixed(2)
                  : "0.00"}
                <span style={{ marginLeft: "0.3rem" }}>
                  (
                  {orderDetails?.orderSummary?.totalTaxPrice
                    ? (
                        orderDetails?.orderSummary?.totalTaxPrice /
                        subTotal(
                          orderDetails?.orderSummary?.totalPrice,
                          orderDetails?.orderSummary?.totalShippingPrice,
                          orderDetails?.orderSummary?.totalTaxPrice,
                        )
                      )?.toFixed(2)
                    : "0.00"}
                  %)
                </span>
              </p>
            </S.PriceWrapper>

            {!!additionalCostOrDiscount && additionalCostOrDiscount !== 0 && (
              <S.PriceWrapper variant="discount">
                <p>
                  {additionalCostOrDiscount < 0 && "Discount:"}
                  {additionalCostOrDiscount > 0 && "Additional cost"}
                </p>
                <p>{`${additionalCostOrDiscount / 100}`}</p>
              </S.PriceWrapper>
            )}
            <S.PriceWrapper variant="total">
              <p>Total:</p>
              <p>
                $ {(orderDetails?.orderSummary?.totalPrice / 100).toFixed(2)}
              </p>
            </S.PriceWrapper>
          </S.PriceDetails>
        </S.PriceContainer>
      </OrdersAccordion>
    </>
  );
}
