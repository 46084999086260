import { Button } from "../../../../../../components/New/Button";
import { ModalSubTitle } from "../../../../../../components/New/Modal/styles";
import { SelectInput } from "../../../../../../components/SelectInput";
import * as Dialog from "@radix-ui/react-dialog";
import * as S from "./styles";
import { EbayCancelReason, ShopifyCancelReason } from "./constants";
import { useEffect, useState } from "react";
import { wrapperRequests } from "../../../../../../services/api";
import { routesURL } from "../../../../../../services/routesUrl";
import { StoreToRefund } from "../../../../../../@types/Stores";
import { SearchableSelectInput } from "../../../../../../components/New/SearchableSelectInput";
import { toastify } from "../../../../../../shared/Toastify";
import { useNavigate } from "react-router-dom";

interface CancelModalProps {
  order: {
    orderId: string;
    sourceMarketplace: "Incycle Shopify" | "Incycle Ebay" | "SBC";
  };
}

export function CancelModal({ order }: CancelModalProps) {
  const reasonsOptions = {
    "Incycle Shopify": ShopifyCancelReason,
    "Incycle Ebay": EbayCancelReason,
    SBC: ShopifyCancelReason,
  } as const;
  const { orderId, sourceMarketplace } = order;

  const [allStores, setAllStores] = useState<StoreToRefund[]>([]);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState(
    reasonsOptions[sourceMarketplace][0].value,
  );
  const [selectedStore, setSelectedStore] = useState({
    id: "63647232c09b1fa35103bec6",
    tenantName: "Incycle",
    name: "Pomona Fulfillment Center",
    code: "9",
  });

  const navigate = useNavigate();

  const handleCancelOrder = async () => {
    setLoading(true);
    try {
      await wrapperRequests(
        routesURL.report.orders.cancelOrder(orderId),
        "POST",
        {
          data: {
            reason: reason,
            shopID: selectedStore.code,
            tenantName: selectedStore.tenantName,
          },
        },
      );

      toastify("success", "Cancellation completed", "top-center");
      navigate(`/report/orders/${orderId}`);
    } catch (error) {
      toastify("error", "Cancellation error, try again", "top-center");
    } finally {
      setLoading(false);
    }
  };

  const getAllStoresOptions = async () => {
    try {
      const response = await wrapperRequests(
        routesURL.settings.stores.getCustomAllStores([
          "name",
          "displayName",
          "code",
          "tenantName",
        ]),
        "GET",
      );

      setAllStores(response.data.content);
    } catch (error) {
      throw Error(String(error));
    }
  };

  useEffect(() => {
    getAllStoresOptions();
  }, []);

  return (
    <S.ModalContainer>
      <ModalSubTitle style={{ marginBottom: 16 }}>
        Select a reason for cancellation
      </ModalSubTitle>

      <SelectInput
        options={reasonsOptions[sourceMarketplace]}
        variant="resized"
        onChange={(e) => setReason(e.target.value)}
      />

      <ModalSubTitle style={{ marginTop: 24, marginBottom: 16 }}>
        Which stock is the item coming back to?
      </ModalSubTitle>

      <SearchableSelectInput
        initialValue={selectedStore}
        options={allStores}
        onOptionSelect={(store) => {
          setSelectedStore(store);
        }}
        disabled={loading}
      />

      <S.ButtonAlign>
        <Dialog.Close asChild>
          <Button
            varitant="ghost"
            title="Cancel"
            width="8.2rem"
            disabled={loading}
          />
        </Dialog.Close>
        <Button
          title="Submit Cancellation"
          width="10.5rem"
          disabled={loading}
          isLoading={loading}
          onClick={handleCancelOrder}
        />
      </S.ButtonAlign>
    </S.ModalContainer>
  );
}
