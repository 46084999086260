import * as S from "./styles";
import { PageHeader } from "../../../../../components/Headers/PageHeader";
import { SwitchInput } from "../../../../../components/Switch/SwitchInput";
import { Button } from "../../../../../components/New/Button";
import { Table } from "../../../../../components/New/Table";
import { ColumnDef } from "@tanstack/react-table";
import { Input } from "../../../../../components/New/Input";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  IPrinter,
  IStore,
  UpdateShopInputDTO,
} from "../../../../../@types/Stores";
import { Modal } from "../../../../../components/New/Modal";
import { ReduceString } from "../../../../../shared/ReduceString";
import { PrintersModalContent } from "../components/PrintersModalContent";
import { StoreSchema, TStoreSchema } from "./schema";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { StoresContext } from "../../../../../contexts/StoresContext";
import { useContextSelector } from "use-context-selector";
import {
  hasUnsavedChanges,
  useLeavePageConfirm,
} from "../../../../../shared/HasUnsavedChanges";
import { LuTrash2 } from "react-icons/lu";
import { DeleteModal } from "../../../../../components/New/Modal/components/DeleteModal";
import { wrapperRequests } from "../../../../../services/api";
import { routesURL } from "../../../../../services/routesUrl";
import { toastify } from "../../../../../shared/Toastify";
import { AxiosError } from "axios";

export function RegisterStore() {
  const [initialValues, setInicialValues] = useState({} as UpdateShopInputDTO);
  const [storeData, setStoreData] = useState({} as IStore);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
    setValue,
    watch,
  } = useForm<TStoreSchema>({
    resolver: zodResolver(StoreSchema),
  });

  const { editStore, fetchStore, selectedStore, setSelectedStore } =
    useContextSelector(StoresContext, (context) => {
      return context;
    });

  const location = useLocation();
  const navigate = useNavigate();
  const storeId = location.pathname.split("/").pop();
  const active = watch("active");
  const currentValues = watch();

  const handleRegisterStore = async (data: TStoreSchema) => {
    setIsLoading(true);

    const dataWithValidValues = Object.fromEntries(
      Object.entries(data).filter(
        ([key, value]) =>
          key === "zapietLocationIDs" || (value !== "" && value !== 0),
      ),
    );

    const validDataWithZapietIds = {
      ...dataWithValidValues,
      zapietLocationIDs:
        typeof dataWithValidValues?.zapietLocationIDs === "string"
          ? dataWithValidValues.zapietLocationIDs
              .split(",")
              .map((str) => str.trim())
              .filter(
                (str) =>
                  str !== "" || dataWithValidValues.zapietLocationIDs === "",
              )
          : undefined,
    };

    await editStore(validDataWithZapietIds);

    navigate("/settings/stores");
  };

  const handleDelete = async (id: string) => {
    setIsLoading(true);
    try {
      await wrapperRequests(
        routesURL.settings.stores.deleteStorePrinter(
          storeData.tenantName,
          storeData.code,
          id,
        ),
        "PUT",
      );

      setSelectedStore((prevState) => {
        return {
          ...prevState,
          printers: prevState.printers?.filter((printer) => printer.id !== id),
        };
      });

      toastify("success", "Printed deleted", "top-center");
    } catch (error) {
      if (error instanceof AxiosError) {
        toastify("error", error.response?.data.message, "top-center");
        throw Error(error.response?.data.message);
      }

      toastify("error", "Printed deleted", "top-center");
      throw Error(String(error));
    } finally {
      setIsLoading(false);
    }
  };

  const getStore = async (storeId: string) => {
    const response = await fetchStore(storeId);

    setStoreData(response);

    setInicialValues({
      active: response.active || false,
      displayName: response.displayName || "",
      channelAdvisorDistributionCenterID:
        response.channelAdvisorDistributionCenterID || "",
      channelAdvisorDistributionCenterCode:
        response.channelAdvisorDistributionCenterCode || "",
      geolocationLat: response.geolocationLat?.toString() || "",
      geolocationLon: response.geolocationLon?.toString() || "",
      shipstationTagIdShipping:
        response.shipStationProperties?.tagIdShopShipping || "",
      shipstationTagIdDelivery:
        response.shipStationProperties?.tagIdDelivery || "",
      shipstationTagIdStorePickup:
        response.shipStationProperties?.tagIdStorePickup || "",
      shipstationCompanyName: response.shipStationProperties?.companyName || "",
      zapietLocationIDs:
        response.shipStationProperties?.zapietLocationIDs.join(", ") || "",
    });

    reset({
      active: response.active || false,
      displayName: response.displayName,
      channelAdvisorDistributionCenterID:
        response.channelAdvisorDistributionCenterID,
      channelAdvisorDistributionCenterCode:
        response.channelAdvisorDistributionCenterCode,
      geolocationLat: response.geolocationLat?.toString(),
      geolocationLon: response.geolocationLon?.toString(),
      shipstationTagIdShipping:
        response.shipStationProperties?.tagIdShopShipping,
      shipstationTagIdDelivery: response.shipStationProperties?.tagIdDelivery,
      shipstationTagIdStorePickup:
        response.shipStationProperties?.tagIdStorePickup,
      shipstationCompanyName: response.shipStationProperties?.companyName || "",
      zapietLocationIDs:
        response.shipStationProperties?.zapietLocationIDs.join(", ") || "",
    });
  };

  useEffect(() => {
    if (storeId) {
      getStore(storeId);
    }

    return () => {
      setSelectedStore({} as IStore);
    };
  }, [storeId]);

  useLeavePageConfirm(hasUnsavedChanges(initialValues, currentValues));

  const columns: ColumnDef<IPrinter>[] = [
    {
      accessorKey: "id",
      header: "ID",
      cell: (props) => <p>{props.getValue()}</p>,
    },
    {
      accessorKey: "serialNumber",
      header: "Serial number",
      cell: (props) => <p>{props.getValue()}</p>,
    },
    {
      accessorKey: "tag",
      header: "Tag",
      size: 170,
      cell: (props) => (
        <p title={props.getValue<string>()}>
          {ReduceString(props.getValue<string>(), 15)}
        </p>
      ),
    },
    {
      accessorKey: "active",
      size: 120,
      header: "Active",
      cell: (props) => (
        <S.RowValueContainer>
          {props.getValue() ? "On" : "Off"}
        </S.RowValueContainer>
      ),
    },
    {
      accessorKey: "activeForOrderItem",
      size: 120,
      header: "Order",
      cell: (props) => (
        <S.RowValueContainer>
          {props.getValue() ? "On" : "Off"}
        </S.RowValueContainer>
      ),
    },
    {
      accessorKey: "size",
      size: 190,
      header: "Size",
      cell: (props) => <p>{props.getValue()}</p>,
    },
    {
      accessorKey: "activeForProductPrice",
      size: 140,
      header: "Product Price",
      cell: (props) => (
        <S.RowValueContainer>
          {props.getValue() ? "On" : "Off"}
        </S.RowValueContainer>
      ),
    },
    {
      accessorKey: "activeForWarehouseLabel",
      size: 170,
      header: "Warehouse Label",
      cell: (props) => (
        <S.RowValueContainer>
          {props.getValue() ? "On" : "Off"}
        </S.RowValueContainer>
      ),
    },
    {
      accessorKey: "activeForTransferCompleteLabel",
      size: 140,
      header: "Transfer label",
      cell: (props) => (
        <S.RowValueContainer>
          {props.getValue() ? "On" : "Off"}
        </S.RowValueContainer>
      ),
    },
    {
      accessorKey: "activeForManualTransferCompleteLabel",
      size: 210,
      header: "Manual Transfer Label",
      cell: (props) => (
        <S.RowValueContainer>
          {props.getValue() ? "On" : "Off"}
        </S.RowValueContainer>
      ),
    },
    {
      accessorKey: "",
      size: 100,
      header: "Actions",
      cell: (props) => (
        <S.IconContainer title="Edit">
          <MdOutlineModeEditOutline size={24} />

          <Modal
            customButtons
            modalContent={DeleteModal({
              message: `Are you sure you want to delete printer tag ${props.row.original.tag}?`,
              onClick: () => handleDelete(props.row.original.id),
              isLoading: isLoading,
            })}
            title="Delete printer"
            width="21.25rem"
          >
            <LuTrash2
              size={22}
              title="Delete"
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Modal>
        </S.IconContainer>
      ),
    },
  ];

  return (
    <S.RegisterStoreContainer>
      <PageHeader
        title="Edit Store"
        customBreadcrumbString={selectedStore.displayName}
      />

      <S.RegisterStoreForm onSubmit={handleSubmit(handleRegisterStore)}>
        <S.FieldsContainer>
          <SwitchInput
            title="Status"
            label={active ? "Active" : "Disabled"}
            checked={!!active}
            onChange={() => setValue("active", !active)}
          />
          {/* <Input disabled title="Select Store(s)" /> */}
        </S.FieldsContainer>

        <h3>Store Information</h3>
        <S.FieldsContainer>
          <Input
            defaultValue={selectedStore.name}
            disabled
            hasAsterisk
            title="Store Name"
          />
          <Input
            title="Display Name"
            defaultValue={selectedStore.displayName}
            {...register("displayName")}
            errorMessage={errors.displayName?.message}
            hasAsterisk
          />
          <Input
            defaultValue={selectedStore.shopMetadata?.id}
            disabled
            title="Store ID"
            hasAsterisk
          />
        </S.FieldsContainer>
        <Input
          defaultValue={selectedStore?.address1 || ""}
          disabled
          title="Adress"
          hasAsterisk
        />

        <S.FieldsContainer>
          <Input
            defaultValue={selectedStore.tenantName}
            disabled
            title="POS Accont Name"
            hasAsterisk
          />
          <Input
            defaultValue={selectedStore?.tenant?.lightspeedAccountId || ""}
            disabled
            title="POS Accont Number"
            hasAsterisk
          />
          <Input
            defaultValue={selectedStore.channelAdvisorDistributionCenterID}
            title="Channel Advisor Id"
            type="number"
            hasAsterisk
            {...register("channelAdvisorDistributionCenterID")}
            errorMessage={errors.channelAdvisorDistributionCenterID?.message}
          />
        </S.FieldsContainer>

        <S.FieldsContainer>
          <Input
            defaultValue={selectedStore.channelAdvisorDistributionCenterCode}
            title="Channel Advisor Code"
            hasAsterisk
            {...register("channelAdvisorDistributionCenterCode")}
            errorMessage={errors.channelAdvisorDistributionCenterCode?.message}
          />
        </S.FieldsContainer>
        <S.Separator style={{ margin: "2.18rem 0" }} />

        <h3>Location Coordinates</h3>
        <S.FieldsContainer>
          <Input
            defaultValue={selectedStore.geolocationLat}
            title="Geolocation Latitude"
            hasAsterisk
            type="number"
            {...register("geolocationLat")}
            errorMessage={errors.geolocationLat?.message}
          />
          <Input
            defaultValue={selectedStore.geolocationLon}
            title="Geolocation Longitude"
            hasAsterisk
            type="number"
            {...register("geolocationLon")}
            errorMessage={errors.geolocationLon?.message}
          />
        </S.FieldsContainer>
        <S.Separator style={{ margin: "2.5rem 0" }} />

        <h3>Shipping Information</h3>
        <S.FieldsContainer>
          <Input
            title="ShipStation Properties Tag Id Shop Shipping *"
            defaultValue={
              selectedStore.shipStationProperties?.tagIdShopShipping
            }
            {...register("shipstationTagIdShipping")}
            errorMessage={errors.shipstationTagIdShipping?.message}
            hasAsterisk
          />
          <Input
            title="ShipStation Properties Tag Id Delivery *"
            defaultValue={selectedStore.shipStationProperties?.tagIdDelivery}
            {...register("shipstationTagIdDelivery")}
            errorMessage={errors.shipstationTagIdDelivery?.message}
            hasAsterisk
          />
          <Input
            title="ShipStation Properties Tag Id Store Pickup *"
            defaultValue={selectedStore.shipStationProperties?.tagIdStorePickup}
            {...register("shipstationTagIdStorePickup")}
            errorMessage={errors.shipstationTagIdStorePickup?.message}
            hasAsterisk
          />
        </S.FieldsContainer>

        <S.FieldsContainer>
          <Input
            title="ShipStation Properties Company Name *"
            defaultValue={selectedStore.shipStationProperties?.companyName}
            {...register("shipstationCompanyName")}
            errorMessage={errors.shipstationCompanyName?.message}
            hasAsterisk
          />
        </S.FieldsContainer>

        <S.FieldsContainer>
          <Input
            title="Zapiet Location IDs *"
            defaultValue={selectedStore.shipStationProperties?.zapietLocationIDs.join(
              ", ",
            )}
            {...register("zapietLocationIDs")}
            errorMessage={errors.zapietLocationIDs?.message}
            hasAsterisk
          />
          <Input title="Zapiet Pickup" disabled hasAsterisk />
          <Input title="Zapiet Delivery" disabled hasAsterisk />
        </S.FieldsContainer>

        <S.ButtonAlignContainer>
          <Button title="Submit" isLoading={isLoading} disabled={isLoading} />
        </S.ButtonAlignContainer>

        <S.Separator style={{ margin: "2.5rem 0" }} />

        <h3>Warehouse Information</h3>
        <S.FieldsContainer>
          <Input
            title="WarehouseShop ShopID"
            defaultValue={selectedStore.warehouseShop?.shopID}
            disabled
            hasAsterisk
          />
          <Input
            title="WarehouseShop Tenant Name"
            defaultValue={selectedStore.warehouseShop?.tenantName}
            disabled
            hasAsterisk
          />
        </S.FieldsContainer>
      </S.RegisterStoreForm>
      <S.Separator style={{ margin: "2rem 0" }} />

      <S.PrinterTitleAlign style={{ margin: 0, marginBottom: 32 }}>
        <h3 style={{ margin: 0 }}>Printer Information</h3>
        <Modal
          title="Printer Information"
          customButtons
          modalContent={PrintersModalContent({
            storeData: selectedStore,
            printer: {} as IPrinter,
          })}
        >
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            width="9.37rem"
            title="Add a new printer"
          />
        </Modal>
      </S.PrinterTitleAlign>

      {selectedStore?.printers && selectedStore?.printers[0] && (
        <Table
          columns={columns}
          columnToFilter="task"
          completeData={selectedStore}
          data={selectedStore?.printers}
          key={selectedStore?.printers.length}
          modalOnClick
        />
      )}
      <S.Separator style={{ margin: "2.5rem 0" }} />

      <h3>Shop Metadata</h3>
      <S.FieldsContainer>
        <Input
          title="Shops._id *"
          defaultValue={selectedStore.shipstationShopMetadata?.id}
          disabled
          hasAsterisk
        />
        <Input
          title="Shipstation-shop-metadata Region Name *"
          defaultValue={selectedStore.shipstationShopMetadata?.regionName}
          disabled
          hasAsterisk
        />
        <Input
          title="Shipstation-shop-metadata UserId *"
          defaultValue={selectedStore.shipstationShopMetadata?.userId}
          disabled
          hasAsterisk
        />
      </S.FieldsContainer>

      <S.FieldsContainer>
        <Input
          title="Shipstation-shop-metadata Shops *"
          defaultValue={selectedStore.shipstationShopMetadata?.shops.join(", ")}
          disabled
          hasAsterisk
        />
      </S.FieldsContainer>
    </S.RegisterStoreContainer>
  );
}
