export const ShopifyCancelReason = [
  {
    id: "1",
    label: "Customer",
    value: "CUSTOMER",
  },
  {
    id: "2",
    label: "Declined",
    value: "DECLINED",
  },
  {
    id: "3",
    label: "Fraud",
    value: "FRAUD",
  },
  {
    id: "4",
    label: "Inventory",
    value: "INVENTORY",
  },
  {
    id: "5",
    label: "Other",
    value: "OTHER",
  },
  {
    id: "6",
    label: "Staff",
    value: "STAFF",
  },
];

export const EbayCancelReason = [
  {
    id: "1",
    label: "Address Issues",
    value: "ADDRESS_ISSUES",
  },
  {
    id: "2",
    label: "Buyer Asked Cancel",
    value: "BUYER_ASKED_CANCEL",
  },
  {
    id: "3",
    label: "Order Unpaid",
    value: "ORDER_UNPAID",
  },
  {
    id: "4",
    label: "Out of Stock or Cannot Cancel ",
    value: "OUT_OF_STOCK_OR_CANNOT_FULFILL",
  },
  {
    id: "5",
    label: "Unknown",
    value: "UNKNOWN",
  },
];
