import { useTheme } from "styled-components";
import { Button } from "../../../Button";
import * as S from "./styles";
import * as Dialog from "@radix-ui/react-dialog";
import { ButtonHTMLAttributes } from "react";

interface DeleteModalProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  message: string;
}

export function DeleteModal({ isLoading, message, ...rest }: DeleteModalProps) {
  const theme = useTheme();

  return (
    <S.Container>
      <span>{message}</span>

      <div>
        <Dialog.Close asChild>
          <Button
            title="Cancel"
            disabled={isLoading}
            varitant="ghost"
            width="4.6rem"
            style={{
              border: `1px solid ${theme.colors["gray-100"]}`,
              color: theme.newColors["gray-800"],
            }}
          />
        </Dialog.Close>

        <Button
          disabled={isLoading}
          isLoading={isLoading}
          title="Delete"
          width="4.6rem"
          style={{
            backgroundColor: theme.newColors["red-600"],
          }}
          {...rest}
        />
      </div>
    </S.Container>
  );
}
